@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: background-color 0.3s ease;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.anticon {
  vertical-align: middle !important;
}

.ant-form-item-label {
  font-weight: 500;
}

@media (max-width: 768px) {
  .ant-table {
    display: block;
    background-color: transparent;

    thead {
      display: none;
    }
    tr,
    tbody{
      display: block;
    }
    td {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      &:empty{
        display: none;
      }
      word-break: break-all;
    }
    tr {
      margin-bottom: 10px;
      border-radius: 4px;
      background-color: var(--ant-component-background);
      border: 1px solid #d4d4d4;
      
      .dark & {
        background-color: #1c1c1c;
        border-color: #333;
      }
    }
    .ant-btn {
      flex-grow: 1;
    }
  }
}

.ant-layout-sider-trigger > .anticon {
  transform: rotate(180deg);
}

.ant-table-row{
  cursor: pointer;
}

.ant-typography code {
  border: none;
  font-family: 'SFMono-Regular',Consolas,'Liberation Mono',Menlo,Courier,monospace !important;
  direction: ltr;
  display: inline-block;
  
  .dark & {
    background-color: var(--ant-component-background);
  }
}

.ant-layout.ant-layout-has-sider >.ant-layout {
  width: auto !important;
}

.ant-progress-line{
  margin-bottom: 0 !important;
}

.ant-progress .ant-progress-text{
  vertical-align: baseline !important;
}

// Dark mode specific overrides
.dark {
  .ant-layout {
    background-color: var(--ant-layout-body-background);
  }
  
  .ant-card {
    background-color: var(--ant-component-background);
  }
  
  .ant-table {
    background-color: var(--ant-component-background);
    
    .ant-table-cell {
      background-color: var(--ant-component-background);
      border-color: #333;
    }
  }
  
  .ant-divider {
    border-color: var(--ant-border-color-split);
  }
}